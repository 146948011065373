<template>
  <div class="single-sign">
    <h2 class="fs-24 marginB28 dark-black">{{$t('singleSign')}}</h2>
    <p class="gray lineH20">{{$t('organization')}}</p>
    <p class="gray lineH20">{{$t('credentials')}}</p>
    <div class="company-input">
      <el-input
        prefix-icon="iconfont icon-a-Group545"
        tabindex='1'
        v-model="companyDomain"
        @keyup.enter.native="nextOpaertaion"
        @blur="blurInput"
        :placeholder="$t('companyPlaceholder')"></el-input>
      <div
        v-show="isShowError"
        class="warning">
        <i class="el-icon-warning-outline"></i>
        <span class="warningTip">{{$t('errorOkta')}}</span>
        <span
          class='cursor border'
          @click="goToConfig">{{$t('ssoConfig')}}</span>
      </div>
    </div>
    <div>
      <el-button
        v-repeat-click="{event:nextOpaertaion}"
        class="sign-submit-btn"
        :disabled='isDisabeBtn'
        :loading="isLoading">{{$t('login')}}</el-button>
    </div>
    <div class="gray trouble">
      <span>{{$t('trouble')}}</span>
      <span
        class="border cursor"
        @click="goToConfig">{{$t('accessRes')}}</span>
    </div>
    <div
      class="gray fw ac cursor"
      @click="withoutSSO">
      <span class="border">{{$t('withoutSSO')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSign',
  data () {
    return {
      companyDomain: '',
      isShowError: false,
      isDisabeBtn: true,
      isLoading: false
    }
  },
  watch: {
    companyDomain: function (newVal) {
      if (!newVal) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    }

  },
  created () {
  },
  methods: {
    withoutSSO () {
      this.routerPush('/signIn')
    },
    goToConfig () {
      this.routerPush('/configPage')
    },
    routerPush (path) {
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    },
    blurInput () {
      if (!this.companyDomain) {
        this.isShowError = false
      }
    },
    nextOpaertaion () {
      if (!this.companyDomain) {
        this.isDisabeBtn = true
        return
      }
      this.isDisabeBtn = false

      this.$axios.post(`/userGroup/user-group/login/saml/redirect?companyDomain=${this.companyDomain}`, {}).then((res) => {
        // 跳转code页面
        if (res.data.errorCode == '80944602') {
          this.isShowError = true
        } else if (res.data.errorCode == '0x0') {
          location.href = res.data.result
        } else if (res.data.errorCode == '80944603') {
          this.$message({
            message: this.$t('error80944603'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80944601') {
          this.$message({
            message: this.$t('error80944601'),
            type: 'error',
            duration: 3000
          })
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
      }).catch()
    }
  }
}
</script>

<style lang="less" scoped>
.company-input {
  margin: 44px 0;
}

.trouble {
  margin: 44px 0 82px;
}

.warning {
  font-size: 13px;
  color: #d93025;
  margin-top: 10px;
}

.warningTip {
  margin-left: 5px;
}
</style>
